/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { memo, useCallback } from 'react';

import useMatchMedia from '../../hooks/matchMedia';
import { useHomeHref } from '../../api/use';
import { useHeaderStore } from '../../hooks/header';

import Link from '../navigation/Link';
import Logo from './Logo';
import DesktopNavigation from '../navigation/DesktopNavigation';
import LanguageNavigation from '../navigation/LanguageNavigation';
import MobileNavigation from '../navigation/MobileNavigation';
import Padder from './Padder';
import { useCursor } from '../cursor';

const Header = () => {
	const isMobileNav = useHeaderStore((state) => state.isMobileNav);
	const background = useHeaderStore((state) => state.background);
	const foreground = useHeaderStore((state) => state.foreground);

	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width:${theme.breakpoint.mobileNav})`);

	const homeHref = useHomeHref();

	const setCursor = useCursor((state) => state.setCursor);
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			setCursor(isMobileNav ? 'defaultSecondary' : 'default');
		},
		[isMobileNav, setCursor]
	);
	const onOverLogo = useCallback(
		(evt) => {
			evt.stopPropagation();
			setCursor(isMobileNav ? 'pointerSecondary' : 'pointer');
		},
		[isMobileNav, setCursor]
	);

	return (
		<div
			onMouseOver={onOver}
			css={(theme) => css`
				--header-fg: ${isMobileNav
					? theme.color.bg
					: foreground === 'light'
					? theme.color.bg
					: foreground === 'dark'
					? theme.color.fg
					: theme.color.secondaryDarkContrast};
				--header-bg: ${isMobileNav
					? theme.color.primaryDark
					: background === 'light'
					? theme.color.bg
					: background === 'dark'
					? theme.color.fg
					: theme.color.secondaryDark};

				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				z-index: ${theme.zIndex.header};

				display: flex;
				justify-content: space-between;
				align-items: center;

				height: ${theme.dimension.header.height.s};

				transition: color 0.3s ease, background-color 0.3s ease;
				color: var(--header-fg);
				background: var(--header-bg);

				@media (min-width: ${theme.breakpoint.m}) {
					height: ${theme.dimension.header.height.m};
				}
			`}
		>
			<Padder>
				<Link
					onMouseOver={onOverLogo}
					href={homeHref}
					title="Homepage"
					tabIndex={1}
					id="logo"
					css={css`
						color: currentColor;
					`}
				>
					<Logo
						css={(theme) => css`
							width: ${theme.dimension.header.logoWidth.s};

							@media (min-width: ${theme.breakpoint.m}) {
								width: ${theme.dimension.header.logoWidth.m};
							}

							@media (min-width: ${theme.breakpoint.l}) {
								width: ${theme.dimension.header.logoWidth.l};
							}
						`}
					/>
				</Link>
			</Padder>
			<div
				css={css`
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex: 1 1 auto;
				`}
			>
				{isDesktop ? (
					<Padder
						css={css`
							display: flex;
							align-items: center;
						`}
					>
						<DesktopNavigation />
						<LanguageNavigation />
					</Padder>
				) : (
					<MobileNavigation />
				)}
			</div>
		</div>
	);
};

export default memo(Header);
