/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { VisuallyHidden } from 'react-aria';

const barKeyframes = keyframes`
	0% { right: 100%;left: 0%; }
	50% { right: 0%;left: 0%; }
	100% { right: 0%;left: 100%; }
`;

const LoadingScreen = () => {
	return (
		<div
			css={(theme) => css`
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: ${theme.color.loadingBackground};
				margin: 0;
				padding: 0;
				z-index: ${theme.zIndex.loadingScreen};
			`}
			aria-busy="true"
			aria-live="polite"
		>
			<VisuallyHidden>Loading...</VisuallyHidden>
			<div
				css={(theme) => css`
					position: absolute;
					left: 0;
					right: 0;
					height: 4px;
					bottom: 0;
					background: ${theme.color.primaryDark};
					animation: ${barKeyframes} 1000ms linear;
					animation-iteration-count: infinite;
				`}
			/>
		</div>
	);
};

export default LoadingScreen;
