/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useHover } from 'react-aria';
import { useRef, useCallback } from 'react';

import { useCursor } from '../cursor';
import Link from './Link';
import { AnimatePresence, motion } from 'framer-motion';

const variants = {
	off: { opacity: 0, y: '100%' },
	on: { opacity: 1, y: '0%' },
};

const MobileNavLink = ({ href, label, isActive, ...props }) => {
	const setCursor = useCursor((state) => state.setCursor);
	const cnt = useRef(0);
	const { hoverProps, isHovered } = useHover({});
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			cnt.current++;
			setCursor('pointerSecondary', cnt.current);
		},
		[cnt, setCursor]
	);

	return (
		<motion.div variants={variants}>
			<Link
				onMouseOver={onOver}
				{...hoverProps}
				href={href}
				css={(theme) => css`
					text-decoration: none;
					font-size: ${theme.pxToFontSize(30)};
					line-height: ${(1 / 3) * 5};
					color: inherit;
					position: relative;

					&:focus-visible {
						outline: none;
					}
				`}
				{...props}
			>
				{label}
				<AnimatePresence>
					{(isHovered || isActive) && (
						<motion.div
							key={cnt.current}
							initial={{ left: '0%', width: '0%' }}
							exit={{ left: '100%', width: '0%' }}
							animate={{ left: '0%', width: '100%', transition: { delay: 0.2 } }}
							transition={{ duration: 0.2, ease: 'linear' }}
							css={css`
								position: absolute;
								top: 100%;
								height: 0.08em;
								background-color: currentColor;
							`}
						/>
					)}
				</AnimatePresence>
			</Link>
		</motion.div>
	);
};

export default MobileNavLink;
