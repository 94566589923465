/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const NavUl = ({ link: Link, li: Li, item: Item, ul: UL, hierarchy, ...props }) =>
	!!hierarchy && (
		<ul
			css={css`
				list-style: none;
				margin: 0;
				padding: 0;
			`}
			{...props}
		>
			{hierarchy.map((page) => (
				<Item key={page.id} page={page} link={Link} li={Li} item={Item} ul={NavUl} />
			))}
		</ul>
	);

export default NavUl;
