/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';

import Nav from './Nav';

const DesktopNavigation = () => {
	return (
		<Nav
			css={css`
				flex: 1 1 auto;
				display: flex;
				justify-content: center;

				& > ul {
					display: flex;
				}
			`}
		/>
	);
};

export default memo(DesktopNavigation);
