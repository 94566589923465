/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createPortal } from 'react-dom';
import { FocusScope } from 'react-aria';

import { useScrollLock } from '../hooks/ScrollLock';
import { Typography } from './typography';

const ErrorScreen = () => {
	const { wrapperProps } = useScrollLock('error', { initialAquire: true });

	return createPortal(
		<FocusScope contain restoreFocus autoFocus>
			<div
				css={(theme) =>
					!!theme.zIndex
						? css`
								background: ${theme.primary};
								color: ${theme.primaryContrast};
								margin: 0;
								padding: 0;
								z-index: ${theme.zIndex.errorScreen};
						  `
						: css`
								background-color: red;
						  `
				}
				aria-invalid="true"
				{...wrapperProps}
			>
				<div
					aria-label="error"
					css={css`
						width: 100%;
						height: 100%;
						background: transparent;
						margin: 0;
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
					`}
				>
					<Typography>
						There was an error.
						<br />
						Please <a href="./">reload.</a>
					</Typography>
				</div>
			</div>
		</FocusScope>,
		document.body
	);
};
export default ErrorScreen;
