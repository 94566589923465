import { useTheme } from '@emotion/react';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigate } from '../../api/use';
import { useCursor } from '../cursor';

/*
 * when clicking an internal link, we first have to load the page content
 * and replace history afterwards.
 * otherwise, scroll restore would fire too soon
 * please use this component for all internal links
 */
const Link = ({ href, children, replace, jumpHistory, onNavigate = () => {}, cursor = 'pointer', ...props }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const setCursor = useCursor((state) => state.setCursor);
	const theme = useTheme();
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			setCursor(cursor + (theme.color.cursorColor || ''));
		},
		[cursor, setCursor, theme.color.cursorColor]
	);

	const onClick = useCallback(
		(evt) => {
			evt.preventDefault();
			if (!href && !jumpHistory) return;
			navigate(href, {
				onNavigate,
				jumpHistory,
				options: { replace, state: { lastLocation: location.key } },
			});
		},
		[href, navigate, onNavigate, replace, jumpHistory, location]
	);

	return (
		<a onMouseOver={onOver} href={href} onClick={onClick} {...props}>
			{children}
		</a>
	);
};

export default Link;
