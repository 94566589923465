import create from 'zustand';
import { motion } from 'framer-motion';

const variants = {
	fade: {
		initial: { opacity: 0, scale: 2 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0 },
	},
	arrowRight: {
		initial: { opacity: 0, scale: 2 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0 },
	},
	arrowLeft: {
		initial: { opacity: 0, scale: 2 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0 },
	},
};

const Pointer = ({ r, secondary }) => (
	<motion.circle
		initial={{ r: 10 }}
		animate={{ r }}
		exit={{ r: 0 }}
		cx="80"
		cy="80"
		r="5"
		fill={secondary ? '#6419BE' : '#005470'}
	/>
);

const ArrowRight = () => (
	<motion.g variants={variants.arrowRight}>
		<circle cx="80" cy="80" r="30" fill="#08556F" />
		<path
			fill="#FFF"
			d="M84.8 91.4a1 1 0 0 1-1.4 0 1 1 0 0 1 0-1.4l9-9H63.8a1 1 0 1 1 0-2h28.6l-9-9a1 1 0 1 1 1.4-1.4L96.2 80 84.8 91.4z"
		/>
	</motion.g>
);

const Next = () => (
	<motion.g variants={variants.arrowRight}>
		<path
			fill="none"
			stroke="#000"
			strokeMiterlimit="10"
			strokeWidth="2"
			d="m86.291 69.292 10.894 10.894L86.291 91.08M58.338 80.186h38.849"
		/>
	</motion.g>
);

const Prev = () => (
	<motion.g variants={variants.arrowLeft}>
		<path
			fill="none"
			stroke="#000"
			strokeMiterlimit="10"
			strokeWidth="2"
			d="M69.234 91.08 58.34 80.186l10.894-10.894M97.187 80.186H58.338"
		/>
	</motion.g>
);

const Contact = () => (
	<motion.g variants={variants.fade}>
		<circle cx="80" cy="80" r="30" fill="#0F0F0F" />
		<path
			fill="#FFF"
			d="M70.5 71.3h19.3c.4 0 .7 0 1 .3l-10 10a1 1 0 0 1-1.3 0l-10-10a2 2 0 0 1 1-.3zm-3.7 11.2H62a.5.5 0 1 1 0-1.1h4.8a.5.5 0 1 1 0 1zm0-2.3h-3.2a.5.5 0 1 1 0-1.1h3.2a.5.5 0 1 1 0 1zm1.9 7.5a2 2 0 0 1-.2-1V73.3c0-.3 0-.6.2-.9v.1l7.6 7.6-7.6 7.6zM89.8 88.7H70.5a2 2 0 0 1-1-.3l7.6-7.6 1.6 1.6a2 2 0 0 0 2.9 0l1.6-1.6 7.5 7.6h.1a2 2 0 0 1-1 .3zm2-1.9-.3.9v-.1L84 80l7.5-7.6c.2.2.3.5.3.8v13.5z"
		/>
	</motion.g>
);

const Video = () => (
	<motion.g variants={variants.fade}>
		<g transform="translate(-1344 310.982)">
			<circle cx="1424.204" cy="-230.982" r="80" fill="#262528" />
			<path
				fill="#FFF"
				d="M1444.281-234.437c1.908 1.113 2.552 3.563 1.439 5.471-.348.596-.843 1.092-1.439 1.439l-24.062 14.036c-1.908 1.113-4.358.468-5.47-1.44-.357-.612-.545-1.307-.545-2.015v-28.072c0-2.209 1.791-4 4-4 .708 0 1.403.188 2.015.545l24.062 14.036z"
			/>
		</g>
	</motion.g>
);

const LinkedIn = () => (
	<motion.g variants={variants.fade}>
		<circle cx="80" cy="80" r="30" fill="#0F0F0F" />
		<path
			fill="#FFF"
			d="M68 73h5.8v17.6H68zm2.9-2.4c-2 0-3.3-1.4-3.3-3 0-1.8 1.3-3.1 3.3-3.1 2 0 3.2 1.3 3.3 3 0 1.7-1.3 3-3.3 3zm24 19.9H89v-9.4c0-2.3-.8-4-3-4-1.5 0-2.5 1.2-3 2.2a4 4 0 0 0-.1 1.4v9.8H77V73h6v2.5a5.8 5.8 0 0 1 5.2-3c3.9 0 6.8 2.6 6.8 8v10z"
		/>
	</motion.g>
);

const cursors = {
	none: null,
	noneSecondary: null,
	default: <Pointer r={10} />,
	pointer: <Pointer r={5} />,
	defaultSecondary: <Pointer secondary r={10} />,
	pointerSecondary: <Pointer secondary r={5} />,
	right: <ArrowRight />,
	rightSecondary: <ArrowRight />,
	next: <Next />,
	nextSecondary: <Next />,
	prev: <Prev />,
	prevSecondary: <Prev />,
	contact: <Contact />,
	contactSecondary: <Contact />,
	linkedIn: <LinkedIn />,
	linkedInSecondary: <LinkedIn />,
	video: <Video />,
	videoSecondary: <Video />,
};

export const useCursor = create((set) => ({
	cursor: cursors['default'],
	key: undefined,
	setCursor: (mode, key) => {
		set(() => {
			return { cursor: cursors[mode] !== undefined ? cursors[mode] : cursors['default'], key: mode + (key || '') };
		});
	},
}));
