/** @jsxImportSource @emotion/react */
import { ThemeProvider, Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense, useEffect } from 'react';

import ApiProvider from './api/provider';

import ErrorBoundary from './ErrorBoundary';
import PageWrap from './views/PageWrap';

import { theme, globalStyles } from './theme';

import Header from './components/layout/Header';
import Cursor from './components/cursor/Cursor';
import { useCursor } from './components/cursor';

const App = () => {
	const setCursor = useCursor((state) => state.setCursor);

	useEffect(() => document.body.addEventListener('mouseover', () => setCursor('default')), [setCursor]);

	return (
		<Suspense fallback={<div />}>
			<HelmetProvider>
				<ThemeProvider theme={theme}>
					<Global styles={globalStyles} />
					<ErrorBoundary>
						<ApiProvider>
							<Header />
							<PageWrap />
						</ApiProvider>
					</ErrorBoundary>
					<Cursor />
				</ThemeProvider>
			</HelmetProvider>
		</Suspense>
	);
};

export default App;
