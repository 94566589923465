/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';

import { useSite, usePage } from '../../api/use';

import NavLink from './NavLink';

const LanguageNavigation = () => {
	const { lang, locale: siteLocale } = useSite();
	const { lang: pageLang } = usePage();

	return (
		<nav
			css={css`
				flex: 1 1 auto;
				display: flex;
				justify-content: flex-end;

				& > * {
					&:not(:last-of-type) {
						margin-right: 0.2em;
					}
					&:not(:first-of-type) {
						margin-left: 0.2em;
					}
				}
			`}
		>
			{!!lang &&
				lang.map(({ locale, label, home }) => (
					<NavLink
						plainLink
						key={locale}
						isActive={siteLocale === locale}
						href={(pageLang && pageLang.find(({ locale: l }) => l === locale)?.link) || home}
						label={label}
					/>
				))}
		</nav>
	);
};

export default memo(LanguageNavigation);
