import create from 'zustand';

let bgTimeout = 0,
	fgTimeout = 0;
export const useHeaderStore = create((set, get) => ({
	isMobileNav: false,
	background: 'light',
	foreground: 'dark',
	setBackground: (mode) => {
		if (get().background === mode || get().isMobileNav) return;
		clearTimeout(bgTimeout);
		bgTimeout = setTimeout(() => {
			set(() => ({ background: mode }));
		}, 100);
	},
	setForeground: (mode) => {
		if (get().foreground === mode || get().isMobileNav) return;
		clearTimeout(fgTimeout);
		fgTimeout = setTimeout(() => {
			set(() => ({ foreground: mode }));
		}, 100);
	},
	setIsMobileNav: (bool) => set(() => ({ isMobileNav: !!bool })),
}));
