import { useNavigation } from '../../api/use';

import NavUl from './NavUl';
import NavLi from './NavLi';
import NavLink from './NavLink';
import NavItem from './NavItem';

const Nav = ({ link: Link = NavLink, li: Li = NavLi, item: Item = NavItem, ul: Ul = NavUl, ...props }) => {
	const { hierarchy } = useNavigation();
	return (
		!!hierarchy && (
			<nav {...props}>
				<Ul hierarchy={hierarchy} link={Link} item={Item} li={Li} ul={Ul} />
			</nav>
		)
	);
};

export default Nav;
