/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import create from 'zustand';

const useContext = create((set, get) => ({
	initial: true,
	stack: {},
	scrollStack: [],
	scrollPos: new Map(),
	set,
	get,
}));

export const useScrollLock = (target, options = { initialAquire: false, isDismissable: true }) => {
	const { scrollStack, scrollPos, set, get } = useContext((state) => state);

	const lock = useCallback(
		(lock, saveTop) => {
			const newStack = [...get().scrollStack];
			const newPos = new Map(get().scrollPos);
			let newInitial = get().initial;

			const top = newStack[0];

			if (top && saveTop) {
				const pos = { x: window.pageXOffset, y: window.pageYOffset };
				newPos.set(top, pos);
			}

			if (lock) {
				if (newStack.includes(target)) {
					newStack.splice(newStack.indexOf(target), 1);
				}

				const pos = get().scrollPos.get(target) || { x: 0, y: 0 };

				newStack.unshift(target);
				newPos.set(target, pos);
				if (newInitial) {
					newInitial = false;
				} else {
					requestAnimationFrame(() => window.scrollTo(pos.x, pos.y));
				}
			} else {
				if (!top || top !== target) {
					console.warn(`Called unlock from ${target} but we're not the top, ${top} is.`);
					return;
				}

				newStack.shift();
				const newTop = newStack[0];
				if (newTop) {
					const pos = get().scrollPos.get(newTop);
					requestAnimationFrame(() => window.scrollTo(pos.x, pos.y));
				}
			}
			set({ initial: newInitial, scrollStack: newStack, scrollPos: newPos });
		},
		[target, set, get]
	);

	if (options.initialAquire && !scrollStack.includes(target)) {
		const newStack = [...scrollStack];
		newStack.splice(0, newStack.length);
		set({ scrollStack: newStack });
		lock(true, true);
	}

	const pos = scrollPos.get(target) || { x: 0, y: 0 };
	const isActive = scrollStack[0] === target;

	return {
		isActive,
		wrapperProps: {
			'aria-hidden': isActive ? 'false' : 'true',
			style: isActive
				? {
						position: '',
						left: '',
						width: '',
						top: '',
						pointerEvents: '',
				  }
				: {
						position: 'fixed',
						left: -pos.x + 'px',
						width: '100%',
						top: -pos.y + 'px',
						pointerEvents: 'none',
				  },
		},
		lock,
	};
};
