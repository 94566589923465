/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const Typography = ({
	as: Component = 'div',
	font = 'default',
	size = 'm',
	weight = 'regular',
	fontStyle = 'normal',
	textDecoration = 'none',
	margin,
	...props
}) => {
	return (
		<Component
			css={(theme) => [
				theme.typography?.getStyle({ theme, font, weight, size, style: fontStyle, decoration: textDecoration }),
				typeof margin !== 'undefined' &&
					css`
						margin: ${margin};
					`,
			]}
			{...props}
		/>
	);
};

export const H1 = (props) => <Typography as="h1" weight="bold" size="xl" {...props} />;

export const H2 = (props) => <Typography as="h2" weight="bold" size="l" {...props} />;

export const H3 = (props) => <Typography as="h3" weight="bold" size="m" {...props} />;

export const H4 = (props) => <Typography as="h3" weight="regular" size="m" fontStyle="italic" {...props} />;

export const H5 = (props) => <Typography as="h3" weight="bold" size="s" {...props} />;

export const H6 = (props) => <Typography as="h3" weight="regular" size="s" fontStyle="italic" {...props} />;

export const P = (props) => <Typography as="p" {...props} />;
