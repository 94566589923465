/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';
import { motion } from 'framer-motion';

import { useSite, usePage } from '../../api/use';

import Link from './Link';

const variants = {
	off: { opacity: 0, x: '100%' },
	on: { opacity: 1, x: '0%' },
};

const MobileLangNav = () => {
	const { lang, locale: siteLocale } = useSite();
	const { lang: pageLang } = usePage();

	return (
		<nav
			css={css`
				flex: 1 1 auto;
				display: flex;
				margin-top: 2em;
			`}
		>
			{!!lang &&
				lang.map(({ locale, label, home }) => (
					<motion.div
						key={locale}
						variants={variants}
						css={(theme) => css`
							font-size: ${theme.pxToFontSize(18)};

							&:not(:last-of-type):after {
								content: '|';
								padding: 0 0.4em;
							}
						`}
					>
						<Link
							href={(pageLang && pageLang.find(({ locale: l }) => l === locale)?.link) || home}
							css={css`
								text-decoration: none;
								color: inherit;
								border-bottom: 1px solid ${siteLocale === locale ? 'currentColor' : 'transparent'};
							`}
						>
							{label}
						</Link>
					</motion.div>
				))}
		</nav>
	);
};

export default memo(MobileLangNav);
