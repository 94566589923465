/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useState, useEffect, useCallback, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { FocusScope, useHover, useFocus } from 'react-aria';
import { motion, AnimatePresence } from 'framer-motion';

import { useScrollLock } from '../../hooks/ScrollLock';
import { useHeaderStore } from '../../hooks/header';
import { useCursor } from '../cursor';

import Nav from './Nav';
import MobileNavLink from './MobileNavLink';
import MobileLangNav from './MobileLangNav';
import Padder from '../layout/Padder';

const variants = {
	main: {
		on: { opacity: 1, transition: { staggerChildren: 0.05, when: 'beforeChildren' } },
		off: {
			opacity: 0,
			transition: { duration: 0.02, staggerChildren: 0.05, staggerDirection: -1, when: 'afterChildren' },
		},
	},
};

const NavigationContent = () => {
	const setIsMobileNav = useHeaderStore((state) => state.setIsMobileNav);

	const { wrapperProps, isActive } = useScrollLock('mobileNav');

	useEffect(() => {
		if (isActive) {
			setIsMobileNav(true);
		}
	}, [setIsMobileNav, isActive]);

	const setCursor = useCursor((state) => state.setCursor);
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			setCursor('defaultSecondary');
		},
		[setCursor]
	);

	return createPortal(
		<AnimatePresence
			onExitComplete={() => {
				setIsMobileNav(false);
			}}
		>
			{!!isActive && (
				<FocusScope contain restoreFocus autoFocus>
					<motion.div
						onMouseOver={onOver}
						initial="off"
						exit="off"
						animate="on"
						variants={variants.main}
						role="navigation"
						aria-label="Main"
						css={(theme) => css`
							position: fixed;
							left: 0;
							top: 0;
							right: 0;
							z-index: ${theme.zIndex.mobileNav};
							background: ${theme.color.primaryDark};
							color: ${theme.color.primaryDarkContrast};
							padding: ${theme.dimension.header.height.s} 0;
							box-sizing: border-box;
							min-height: 100%;
							display: flex;
							flex-direction: column;
						`}
						{...wrapperProps}
					>
						<Padder
							css={css`
								margin: auto 0;
							`}
						>
							<Nav link={MobileNavLink} />
							<MobileLangNav />
						</Padder>
					</motion.div>
				</FocusScope>
			)}
		</AnimatePresence>,
		document.body
	);
};

const Button = memo(({ onClick, isOpen }) => {
	const [hover, setHover] = useState(false);
	const { hoverProps } = useHover({
		onHoverStart: () => {
			setHover(true);
		},
		onHoverEnd: () => {
			setHover(false);
		},
	});

	const { focusProps } = useFocus({
		onFocus: () => {
			setHover(true);
		},
		onBlur: () => {
			setHover(false);
		},
	});

	const setCursor = useCursor((state) => state.setCursor);
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			setCursor(isOpen ? 'pointerSecondary' : 'pointer');
		},
		[isOpen, setCursor]
	);

	return (
		<button
			onMouseOver={onOver}
			{...hoverProps}
			{...focusProps}
			onClick={onClick}
			css={(theme) => [
				theme.resetButton,
				theme.createPadding({ theme }),
				css`
					box-sizing: content-box;
					display: block;
					flex: 0 0 ${theme.pxToUnit(24)};
					height: ${theme.pxToUnit(24)};
					position: relative;
				`,
			]}
		>
			<div
				css={css`
					position: relative;
					height: 100%;
				`}
			>
				<motion.div
					animate={{ y: isOpen ? '0%' : '-200%', rotate: isOpen ? 45 : 0 }}
					css={css`
						position: absolute;
						width: 100%;
						height: 0;
						border-top: 1px solid var(--header-fg);
						border-bottom: 1px solid var(--header-fg);
						top: 50%;
						left: 0;
					`}
				/>
				<motion.div
					animate={{
						originX: isOpen ? 0.5 : 0,
						y: isOpen ? '0%' : '200%',
						rotate: isOpen ? -45 : 0,
						scaleX: hover ? 1 : 0.8,
					}}
					css={(theme) => css`
						position: absolute;
						width: 100%;
						height: 0;
						border-top: 1px solid var(--header-fg);
						border-bottom: 1px solid var(--header-fg);
						top: 50%;
						left: 0;
					`}
				/>
			</div>
		</button>
	);
});

const MobileNavigation = () => {
	const { isActive, lock } = useScrollLock('mobileNav');

	const open = useCallback(() => {
		lock(true, true);
	}, [lock]);

	const close = useCallback(() => {
		lock(false);
	}, [lock]);

	return (
		<Fragment>
			<Button onClick={isActive ? close : open} isOpen={isActive} />
			<NavigationContent />
		</Fragment>
	);
};

export default memo(MobileNavigation);
