/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const NavItem = ({ ul: Ul, item: Item, li: Li, link: Link, page, ...props }) => {
	const { pathname } = useLocation();

	const match = useMemo(() => {
		if (matchPath(page.link, pathname)) {
			return 'current';
		}
		if (page.children && page.children.find((p) => matchPath(p.link, pathname))) {
			return 'section';
		}
		return 'link';
	}, [page, pathname]);

	return (
		<Li page={page} {...props}>
			<Link href={page.link} label={page.menuTitle} isActive={match !== 'link'} />
		</Li>
	);
};

export default NavItem;
