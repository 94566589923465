import { css } from '@emotion/react';

/*
 * maps {key: value} to {key:'var(--NAME-KEY)'}
 */
function applyObjToTheme(theme, obj, name, prefix = '') {
	theme[name] = {};
	Object.keys(obj).forEach((k) => {
		if (typeof obj[k] === 'object') {
			theme[name][k] = {};
			applyObjToTheme(theme[name], obj[k], k, `${prefix}${name}-`);
		} else {
			theme[name][k] = `var(--${prefix}${name}-${k})`;
		}
	});
}

/*
 * maps {key: value} to '--NAME-KEY: value
 */
function applyObjToCss(obj, name) {
	return Object.keys(obj)
		.map((k) => {
			if (typeof obj[k] === 'object') {
				return applyObjToCss(obj[k], `${name}-${k}`);
			}
			return `--${name}-${k}: ${obj[k]};`;
		})
		.join('');
}

export let theme = {};

theme.pxToEm = (px) => px / 16 + 'em';
theme.pxToUnit = theme.pxToEm;
theme.pxToPercent = (px) => (100 / 16) * px + '%';
theme.pxToFontSize = theme.pxToPercent;

// css vars can not be used in media queries
theme.breakpoint = {
	mobileNav: theme.pxToEm(768),

	xs: theme.pxToEm(10),
	s: theme.pxToEm(640),
	m: theme.pxToEm(768),
	l: theme.pxToEm(1024),
	ml: theme.pxToEm(1200),
	xl: theme.pxToEm(1920),
};

const dimension = {
	padding: { s: `${theme.pxToEm(14)}`, m: `${theme.pxToEm(30)} ${theme.pxToEm(30)}` },

	container: {
		padding: { s: '0', m: '0' },
		margin: { s: '0', m: '0' },
	},
	spacing: {
		s: theme.pxToEm(20),
		m: theme.pxToEm(30),
	},
	gap: {
		s: '0px',
		m: '0px',
	},
	maxWidth: theme.pxToEm(1000),
	maxWidthNarrow: theme.pxToEm(540),
	header: {
		height: {
			s: theme.pxToEm(60),
			m: theme.pxToEm(65),
		},
		logoWidth: {
			s: theme.pxToEm(100),
			m: theme.pxToEm(96),
		},
	},
};
applyObjToTheme(theme, dimension, 'dimension');

const zIndexes = ['mobileNav', 'header', 'lightbox', 'overlay', 'loadingScreen', 'errorScreen', 'cursor'];
const zIndex = {};
zIndexes.forEach((k, i) => {
	zIndex[k] = (i + 1) * 10;
});
applyObjToTheme(theme, zIndex, 'zIndex');

const font = {
	default: {
		family: '"Roobert", sans-serif',
		weight: {
			thin: 400,
			regular: 400,
			bold: 400,
		},
		size: {
			xs: {
				s: theme.pxToPercent(13),
				m: theme.pxToPercent(13),
			},
			s: {
				s: theme.pxToPercent(13),
				m: theme.pxToPercent(15),
			},
			m: {
				s: theme.pxToPercent(15),
				m: theme.pxToPercent(22),
			},
			l: {
				s: theme.pxToPercent(18),
				m: theme.pxToPercent(30),
			},
			xl: {
				s: theme.pxToPercent(25),
				m: theme.pxToPercent(45),
			},
			xxl: {
				s: theme.pxToPercent(60),
				m: theme.pxToPercent(100),
			},
		},
		lineHeight: {
			xs: {
				s: 16 / 13,
				m: 18 / 13,
			},
			s: {
				s: 18 / 13,
				m: 21 / 15,
			},
			m: {
				s: 21 / 15,
				m: 30 / 22,
			},
			l: {
				s: 24 / 18,
				m: 40 / 30,
			},
			xl: {
				s: 32 / 25,
				m: 50 / 45,
			},
			xxl: {
				s: 0.9,
				m: 0.9,
			},
		},
	},
};

applyObjToTheme(theme, font, 'font');

theme.typography = {
	getStyle: ({ theme, size = 's', weight = 'regular', font = 'default', style = 'normal', decoration = 'initial' }) => {
		return css`
			font-family: ${theme.font[font].family};
			font-size: ${theme.font[font].size[size].s};
			font-weight: ${theme.font[font].weight[weight]};
			font-style: ${style};
			line-height: ${theme.font[font].lineHeight[size].s};
			text-decoration: ${decoration};

			@media (min-width: ${theme.breakpoint.m}) {
				font-size: ${theme.font[font].size[size].m};
				line-height: ${theme.font[font].lineHeight[size].m};
			}

			@media (min-width: ${theme.breakpoint.l}) {
				font-size: ${theme.font[font].size[size].l};
				line-height: ${theme.font[font].lineHeight[size].l};
			}
		`;
	},
};

export const colorPalettes = {};
colorPalettes.light = {
	fg: '#0F0F0F',
	bg: '#fff',

	fgContrast: 'var(--color-bg)',
	bgContrast: 'var(--color-fbg)',

	lightGrey: '#F4F3F2',
	darkGrey: '#262528',

	lightGreyContrast: 'var(--color-fg)',
	darkGreyContrast: 'var(--color-bg)',

	black: '#262528',
	blackContrast: '#fff',
	blackDark: '#262528',
	blackDarkContrast: '#fff',

	primaryLight: '#d0f5c1',
	primary: '#A0EB82',
	primaryDark: '#005470',

	primaryContrast: 'var(--color-fg)',
	primaryDarkContrast: 'var(--color-bg)',
	primarySelection: 'var(--color-secondary)',

	secondary: '#BE87FF',
	secondaryDark: '#6419BE',

	secondaryContrast: 'var(--color-fg)',
	secondaryDarkContrast: 'var(--color-bg)',
	secondarySelection: 'var(--color-primary)',

	overlayBackground: 'rgba(0, 0, 0, .2)',
	loadingBackground: 'rgba(255, 255, 255, .1)',

	link: 'var(--color-fg)',
	linkActive: 'var(--color-primaryDark)',
	linkVisited: 'var(--color-primary)',

	selection: 'var(--color-primaryContrast)',
	selectionBackground: 'var(--color-primary)',

	success: '#3b9e3b',
	successContrast: '#fff',

	warning: '#ed8a15',
	warningContrast: '#0F0F0F',

	error: '#ef1448',
	errorContrast: '#fff',
};

applyObjToTheme(theme, colorPalettes.light, 'color');
theme.color.cursorColor = '';

colorPalettes.black = {
	...colorPalettes.light,
	base: theme.color.bg,
	fg: theme.color.blackContrast,
	bg: theme.color.black,
	darkGrey: '#666666',
	lightGrey: '#C1C1C1',
	cursorColor: 'Secondary',
};

colorPalettes.primary = {
	...colorPalettes.light,
	base: theme.color.primary,
	fg: theme.color.primaryDarkContrast,
	bg: theme.color.primaryDark,
	darkGrey: '#666666',
	lightGrey: '#C1C1C1',
	cursorColor: 'Secondary',
};

colorPalettes.secondary = {
	...colorPalettes.light,
	base: theme.color.secondary,
	fg: theme.color.secondaryDarkContrast,
	bg: theme.color.secondaryDark,
	darkGrey: '#666666',
	lightGrey: '#C1C1C1',
	cursorColor: 'Secondary',
};

theme.getColors = ({ theme, key, inversed = false }) => css`
	color: ${theme.color[key + (inversed ? 'Contrast' : '')]};
	background: ${theme.color[key + (!inversed ? 'Contrast' : '')]};
	--current-background: ${theme.color[key + (!inversed ? 'Contrast' : '')]};

	& ::selection {
		background: ${theme.color[key + 'Selection']};
	}
`;

theme = {
	...theme,

	resetButton: css`
		cursor: pointer;
		appearance: none;
		font: inherit;
		background: transparent;
		color: inherit;
		border: 0;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border-radius: 0;

		&::-moz-focus-inner {
			border: 0;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:disabled {
			cursor: default;
		}
	`,

	button: ({ theme, isPrimary = false }) => [
		theme.resetButton,
		css`
			display: inline-block;
			text-decoration: none;
			transition: color 0.3s ease;
			color: ${isPrimary ? theme.color.primaryContrast : theme.color.fg};
			background-color: ${isPrimary ? theme.color.primary : theme.color.grey2Contrast};
			border: 1px solid ${isPrimary ? theme.color.primaryContrast : theme.color.fg};
			cursor: pointer;
			padding: 0.8em 1.6em;
			position: relative;
			border-radius: 2em;
			margin: 0.5em 0;

			&:disabled {
				color: ${theme.color.grey1Contrast};
				cursor: inherit;
			}

			&:not(:disabled) {
				&:active,
				&:focus,
				&:hover {
					outline: none;
					color: ${isPrimary ? theme.color.primaryContrast : theme.color.bg};
				}
			}
		`,
	],

	row: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / -2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / -2);
		}
	`,

	col: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / 2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / 2);
		}
	`,

	container: ({ theme, limit = false, narrow = false, padding = true, margin = false }) => [
		limit
			? css`
					max-width: ${narrow ? theme.dimension.maxWidthNarrow : theme.dimension.maxWidth};
					margin-left: auto;
					margin-right: auto;
			  `
			: null,
		padding
			? css`
					box-sizing: border-box;
					${theme.createPadding({ theme })};
			  `
			: null,
		margin
			? css`
					margin-top: ${theme.dimension.container.margin.s};
					margin-bottom: ${theme.dimension.container.margin.s};

					@media (min-width: ${theme.breakpoint.m}) {
						margin-top: ${theme.dimension.container.margin.m};
						margin-bottom: ${theme.dimension.container.margin.m};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						margin-top: ${theme.dimension.container.margin.l};
						margin-bottom: ${theme.dimension.container.margin.l};
					}
			  `
			: null,
	],

	createPadding: ({ theme, s, m }) => css`
		padding: ${(s !== undefined && s) || theme.dimension.padding.s};

		@media (min-width: ${theme.breakpoint.m}) {
			padding: ${(m !== undefined && m) || theme.dimension.padding.m};
		}
	`,

	createMargin: ({ theme, s = '1em', m = '1.4em' }) => css`
		margin: ${s};

		@media (min-width: ${theme.breakpoint.m}) {
			margin: ${m};
		}
	`,

	createGap: ({ theme, s, m }) => css`
		gap: ${(s !== undefined && s) || theme.dimension.gap.s};

		@media (min-width: ${theme.breakpoint.m}) {
			gap: ${(m !== undefined && m) || theme.dimension.gap.m};
		}
	`,
};

export const globalStyles = [
	css`
		@font-face {
			font-family: 'Roobert';
			src: local('Roobert'), url('/_resources/app/font/Roobert-Medium.woff2') format('woff2'),
				url('/_resources/app/font/Roobert-Medium.woff') format('woff');
			font-style: normal;
			font-weight: normal;
			font-display: swap;
		}

		:root {
			${applyObjToCss(colorPalettes.light, 'color')}
			${applyObjToCss(zIndex, 'zIndex')}
			${applyObjToCss(dimension, 'dimension')}
			${applyObjToCss(font, 'font')}
		}

		html,
		body {
			width: 100%;
			min-height: 100%;
			padding: 0;
			margin: 0;
			font-size: 100%;
			font-family: ${theme.font.default.family};
			font-weight: ${theme.font.default.weight.regular};
			font-style: normal;
			line-height: 1.2;
		}

		body {
			color: ${theme.color.fg};
			background-color: ${theme.color.bg};
			-moz-osx-font-smoothing: grayscale;
			font-size-adjust: none;
			-webkit-text-size-adjust: none;
			box-sizing: border-box;
			font-size: 100%;
			overflow-x: hidden;
			overflow-y: scroll;

			@media (min-width: ${theme.breakpoint.m}) {
				font-size: 1.564vw;
			}
		}

		body {
			cursor: none !important;
		}
		body * {
			cursor: none !important;
		}

		::selection {
			background: ${theme.color.selectionBackground};
			color: ${theme.color.selection};
			text-shadow: none;
		}

		a {
			color: ${theme.color.link};
		}
	`,
];

export default theme;
