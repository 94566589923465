/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useCursor } from '.';

const Cursor = (props) => {
	const setCursor = useCursor((state) => state.setCursor);
	const cursor = useCursor((state) => state.cursor);
	const cursorKey = useCursor((state) => state.key);
	const ref = useRef();

	useEffect(() => {
		const moveCursor = (e) => {
			if (e.pointerType !== 'mouse') {
				setCursor('none');
				return;
			}

			ref.current.style.left = e.clientX + 'px';
			ref.current.style.top = e.clientY + 'px';
		};

		const leave = (e) => {
			setCursor('none');
		};

		const enter = (e) => {
			setCursor('default');
		};

		window.addEventListener('pointermove', moveCursor);
		document.addEventListener('mouseleave', leave);
		document.addEventListener('mouseenter', enter);
		return () => {
			window.removeEventListener('pointermove', moveCursor);
			document.removeEventListener('mouseleave', leave);
			document.removeEventListener('mouseenter', enter);
		};
	}, [ref, setCursor]);

	return (
		<div
			ref={ref}
			style={{ left: '-900px', top: '-900px' }}
			css={(theme) => css`
				pointer-events: none;
				position: fixed;
				z-index: 5000;
				left: 0;
				top: 0;
				width: ${theme.pxToUnit(160)};
				height: ${theme.pxToUnit(160)};
				transform: translate(-50%, -50%);
			`}
			{...props}
		>
			<AnimatePresence>
				{!!cursor && (
					<motion.svg
						key={cursorKey}
						initial="initial"
						animate="animate"
						exit="exit"
						viewBox="0 0 160 160"
						overflow="visible"
						css={css`
							position: absolute;
							inset: 0;
						`}
					>
						{cursor}
					</motion.svg>
				)}
			</AnimatePresence>
		</div>
	);
};

export default memo(Cursor);
