/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { lazy, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AnimatePresence } from 'framer-motion';

import useApi from '../api/use';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import ErrorBoundary from '../ErrorBoundary';

const ServiceItem = lazy(() => import('./ServiceItem'));
const ProjectPage = lazy(() => import('./ProjectPage'));
const Page = lazy(() => import('./Page'));
const EventRegistrationPage = lazy(() => import('./EventRegistrationPage'));

const makeAbsoluteLink = (link) => {
	if (link === '' || link === '/') {
		return document.baseURI;
	}
	return `${document.baseURI}${link.substring(1)}`;
};

const pickComponent = (className) => {
	if (!className) {
		return null;
	}
	switch (className) {
		case 'ServiceItem':
			return ServiceItem;
		case 'ProjectPage':
			return ProjectPage;
		case 'EventRegistrationPage':
			return EventRegistrationPage;
		default:
			return Page;
	}
};

const Locker = ({ locationKey, page }) => {
	const Comp = useMemo(() => page?.className && pickComponent(page.className), [page?.className]);
	return <Suspense fallback={<LoadingScreen />}>{!!Comp && <Comp page={page} locationKey={locationKey} />}</Suspense>;
};

export const PageWrap = () => {
	const location = useLocation();

	const {
		state: {
			page: {
				vitality: { error, loaded, loading },
				...page
			},
			site: { canonicalDomain },
		},
		actions: { getPage, getInitial },
		initialized,
	} = useApi();

	useEffect(() => {
		if (!initialized.current) {
			getInitial(location.pathname, location.search);
			return;
		}
		getPage(location.pathname, location.search);
	}, [initialized, location.pathname, location.search, getPage, getInitial]);

	const [store, setStore] = useState({ key: null, page: null });

	useEffect(() => {
		if (loading || !loaded) {
			if (!store.stored) return;
			setStore((store) => ({ ...store, stored: false }));
			return;
		}
		if (store.stored) return;
		setStore({ key: location.state?.fakeKey || location.key, page: { ...page }, stored: true });
	}, [setStore, page, loading, loaded, location.key, location.state?.fakeKey, store.stored, store.page?.loaded]);

	return !store.key ? (
		<LoadingScreen />
	) : (
		<main
			css={(theme) => css`
				background: ${theme.color.bg};
				min-height: 100vh;
			`}
		>
			<Routes>
				<Route
					path="*"
					element={
						<ErrorBoundary>
							{!!loaded && (
								<Helmet>
									<title>{`${page.title}`}</title>
									<meta name="description" content={page.description || ''} />
									<meta name="canonical" content={`${canonicalDomain}${page.$url}`} />
									{page.lang.map(({ locale, link }) => (
										<link
											key={link}
											rel="alternate"
											hreflang={locale.replace('_', '-').toLowerCase()}
											href={makeAbsoluteLink(link)}
										/>
									))}
								</Helmet>
							)}
							{!!error ? (
								<ErrorScreen />
							) : (
								<AnimatePresence>
									<Locker key={store.key} page={store.page} locationKey={store.key} />
								</AnimatePresence>
							)}
						</ErrorBoundary>
					}
				/>
			</Routes>
		</main>
	);
};

export default memo(PageWrap);
