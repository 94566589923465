/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Logo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 96.21 25.775"
		css={css`
			display: block;
			fill: currentColor;
		`}
		{...props}
	>
		<g>
			<path d="M16.193 7.03a8.958 8.958 0 0 1 7.733 4.134l-3.885 2.744a4.552 4.552 0 1 0-3.848 7.127 4.387 4.387 0 0 0 3.813-2.138l3.92 2.745a8.957 8.957 0 0 1-7.733 4.133 9.373 9.373 0 0 1 0-18.745Z" />
			<path d="M33.971 7.03a8.958 8.958 0 0 1 7.734 4.134l-3.885 2.744a4.552 4.552 0 1 0-3.849 7.127 4.387 4.387 0 0 0 3.813-2.138l3.92 2.745a8.957 8.957 0 0 1-7.733 4.133 9.373 9.373 0 0 1 0-18.745Z" />
			<path d="M51.756 7.03a8.958 8.958 0 0 1 7.734 4.134l-3.885 2.744a4.552 4.552 0 1 0-3.849 7.127 4.387 4.387 0 0 0 3.813-2.138l3.92 2.745a8.957 8.957 0 0 1-7.734 4.133 9.373 9.373 0 0 1 0-18.745Z" />
			<path d="M69.065 7.03a9.373 9.373 0 1 1-9.372 9.408 9.173 9.173 0 0 1 9.372-9.408Zm0 14.005a4.634 4.634 0 1 0-4.562-4.6 4.49 4.49 0 0 0 4.562 4.6Z" />
			<path d="M96.209 21.418v-7.2a7.183 7.183 0 0 0-11.618-5.66l-4.748-1.14h.009v17.965h4.846V15.12a3.333 3.333 0 1 1 6.664 0v10.263h4.847" />
			<path d="M.575 9.515v15.868h4.849V9.411H.572Z" />
			<path d="M5.418 7.308v2.108H.57" />
			<path d="M3.065 0A3.065 3.065 0 1 1 0 3.065 3 3 0 0 1 3.065 0Z" />
		</g>
	</svg>
);

export default Logo;
