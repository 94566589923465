/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useHover } from 'react-aria';
import { useRef, useCallback } from 'react';

import { useCursor } from '../cursor';
import Link from './Link';
import { Typography } from '../typography/';
import { AnimatePresence, motion } from 'framer-motion';

const NavLink = ({ plainLink, href, label, isActive, ...props }) => {
	const setCursor = useCursor((state) => state.setCursor);
	const cnt = useRef(0);
	const { hoverProps, isHovered } = useHover({});
	const onOver = useCallback(
		(evt) => {
			evt.stopPropagation();
			cnt.current++;
			setCursor('pointer');
		},
		[cnt, setCursor]
	);

	return (
		<Typography
			onMouseOver={onOver}
			{...hoverProps}
			as={plainLink ? 'a' : Link}
			size="s"
			href={href}
			css={css`
				display: block;
				margin: 0.5em;
				padding-bottom: 0.05em;
				color: inherit;
				position: relative;
			`}
			{...props}
		>
			{label}
			<AnimatePresence>
				{(isHovered || isActive) && (
					<motion.div
						key={cnt.current}
						initial={{ left: '0%', width: '0%' }}
						exit={{ left: '100%', width: '0%' }}
						animate={{ left: '0%', width: '100%', transition: { delay: 0.2 } }}
						transition={{ duration: 0.2, ease: 'linear' }}
						css={css`
							position: absolute;
							top: 100%;
							height: 0.08em;
							background-color: currentColor;
						`}
					/>
				)}
			</AnimatePresence>
		</Typography>
	);
};

export default NavLink;
