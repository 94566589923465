import { Component } from 'react';

import ErrorScreen from './components/ErrorScreen';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		console.error(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorScreen />;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
